var generic = generic || {};
var site = site || {};
var Drupal = Drupal || {};

site.userInfoCookie = site.userInfoCookie || {};
site.userInfoCookie.getValue = site.userInfoCookie.getValue || function () {
  return '';
};

(function ($) {
  site.signin = {
    ...site.signin,
    isSignedIn: 0,
    $template: '',
    initSignInForm: function () {
      var self = this;
      var $login = $('.login', self.$template);
      var $registration = $('.registration', self.$template);
      var $form = $('#login__form', $login);
      var $submit = $('.js-signin-submit', $login);
      var $registrationSwitch = $('.js-register-switch', $login);
      var $returnUrl = $('input[name="RETURN_URL"]', $form);
      var $forgotPassword = $('.js-forgot-password', $login);
      var pathname = window.location.pathname;

      // Make sure that the form action points to the SSL version
      $form.attr('action', 'https://' + window.location.hostname + '/account/signin.tmpl');
      $returnUrl.val('/account/index.tmpl');
      if (Drupal.settings && Drupal.settings.reset_return_url) {
        if (pathname.search('password_request') < 0 || pathname.search('confirm') < 0 || pathname.search('signin') < 0) {
          $returnUrl.val(pathname);
        }
      }

      // setup
      // $('.site-utils__item--account').trigger('click');
      // $registration.show();

      if ($forgotPassword.length > 0) {
        site.signin.forgotPassword({
          resetPassword: true,
          emailNode: $('.js-login-email', $login),
          errorListNode: ' ',
          forgotPasswordLink: $forgotPassword
        });
      }

      $submit.once().click(function (e) {
        e.preventDefault();
        $form.submit();

        return false;
      });

      $registrationSwitch.once().click(function () {
        $login.hide();
        $registration.once('form-initialized', function () {
          self.initRegistrationForm();
        });
        $registration.show();
      });
    },

    initRegistrationForm: function () {
      var self = this;
      var $registration = $('.registration', self.$template);
      var $selectBoxes = $('.selectbox', $registration);
      var $login = $('.login', self.$template);
      var $form = $('#registration__form', $registration);
      var $showPass = $('input[name="show_pass"]', $registration);
      var $password = $('input[name="PASSWORD"]', $registration);
      var $submit = $('.js-registration-submit', $registration);
      var $loginSwitch = $('.js-login-switch', $registration);
      var $returnUrl = $('input[name="RETURN_URL"]', $registration);
      var pathname = window.location.pathname;

      // Make sure that the form action points to the SSL version
      $form.attr('action', 'https://' + window.location.hostname + '/account/signin.tmpl');
      $selectBoxes.trigger('selectbox:loadOnDemand');
      $returnUrl.val('/account/index.tmpl');
      if (Drupal.settings && Drupal.settings.reset_return_url) {
        if (pathname.search('password_request') < 0 || pathname.search('confirm') < 0 || pathname.search('signin') < 0) {
          $returnUrl.val(pathname);
        }
      }

      $showPass.removeAttr('checked');
      $showPass.click(function () {
        var isChecked = $(this).is(':checked');

        $password[0].type = isChecked ? 'text' : 'password';
      });

      $submit.once().click(function (e) {
        e.preventDefault();
        $form.submit();

        return false;
      });

      $loginSwitch.click(function () {
        $registration.hide();
        $login.show();
      });

      if (Drupal.settings && Drupal.settings.common && Drupal.settings.common.adv_legal) {
        var $commercialCheckbox = $('.js-commercial-reg');
        var $commercialLabel = $('.js-commercial-permission');
        var $mobileContainer = $('.js-mobile-container');

        $commercialLabel.on('click', function () {
          if ($commercialCheckbox.is(':checked')) {
            $mobileContainer.removeClass('hidden');
          } else {
            $mobileContainer.addClass('hidden');
          }
        });
      }
    },

    initAccountLinks: function () {
      var self = this;
      var $login = $('.login', self.$template);
      var $registration = $('.registration', self.$template);
      var $account = $('.account-links', self.$template);
      var $welcome = $('.account-links__welcome', self.$template);
      var $userName = $('.account-links__name', self.$template);
      var $social_login = $('.social-login', self.$template);
      var isCr22 = Drupal.settings.global_variables && Drupal.settings.global_variables.cr22;
      var $utilAccountGreetingText = $('.js-gnav-util-account-greetings-text', self.$template);
      var $utilAccountUserName = $('.js-gnav-util-account-username', $utilAccountGreetingText);
      var accountHeaderText = $utilAccountGreetingText.data('account-header');

      function _updateAccountLink($item) {
        var $utilityItem = $item.closest('.js-site-header-formatter-utilities-item');
        var $utilAccountLink = $('.js-gnav-utility-item-trigger', $utilityItem);
        var $utilityItemLinkText = $('.js-gnav-util-item-text', $utilAccountLink);

        if ($utilAccountLink.length > 0 & $item.length > 0) {
          $item.appendTo($utilAccountLink).removeClass('hidden');
          $utilityItemLinkText.addClass('hidden');
        }
      }

      $(self.$template).addClass('is_signed_in');
      var user = site.userInfoCookie.getValue('first_name');

      if (!_.isUndefined(user) && !_.isNull(user)) {
        $userName.html(user);
        $welcome.show();
      } else {
        $welcome.hide();
      }
      if (isCr22 && $utilAccountGreetingText.length > 0) {
        if (!_.isUndefined(user) && !_.isNull(user)) {
          user = user.replace(/\+/gu, ' ');
          $utilAccountUserName.html(user);
          _updateAccountLink($utilAccountGreetingText);
        } else if (accountHeaderText) {
          $utilAccountGreetingText.html(accountHeaderText);
          _updateAccountLink($utilAccountGreetingText);
        }
      }
      $login.hide();
      $registration.hide();
      $social_login.hide();
      $account.show();
    },

    init: function () {
      var self = this;

      if (parseInt(site.signin.isSignedIn) === 0) {
        self.initSignInForm();
      } else if (parseInt(site.signin.isSignedIn) === 1) {
        self.initAccountLinks();
      }
    }
  };

  Drupal.behaviors.signInV1 = {
    attach: function (context) {
      site.signin.isSignedIn = typeof site.userInfoCookie == 'undefined'
                            || _.isUndefined(site.userInfoCookie.getValue('signed_in'))
                            || _.isNull(site.userInfoCookie.getValue('signed_in'))
        ? 0 : site.userInfoCookie.getValue('signed_in');
      site.signin.$template = $('.sign-in', context);
      site.signin.init();
    }
  };
})(jQuery);
